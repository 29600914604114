import React  from 'react';
import './App.css';
import Cake from './components/Cake/Cake';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Cake />
        <h1 className="loading">
          Tud'ovo
          <br />
          está no forno...
        </h1>
        <p id="construction-contact">Contacte-nos através da nossa página do  <a href="https://www.facebook.com/tudoovo" rel="noreferrer" target="_blank">Facebook</a></p>
        <br />
      </header>
    </div>
  );
}

export default App;
